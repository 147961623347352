import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Timeline } from "@/components/ui/timeline";

export function Prometheus() {
  const data = [
    {
      title: "Purpose",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal">
            Project Prometheus is my attempt to create something extraordinary—a digital version of myself. It’s an exercise in seeing how close we can come to building a reflection of who we are. The goal is simple: create a system that feels like you’re talking to me, just through your screen.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://willdam20.wordpress.com/wp-content/uploads/2020/10/cq5dam.web_.1280.1280.jpeg?w=1024"
              alt="Illustration of purpose"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full shadow-lg"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Model and Technology Design",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal">
            At the heart of this project is Hermes, a language model that can already hold a conversation through text. The next step is giving it a voice—literally. I’m exploring two tools for this: Tortoise TTS and Coqui TTS. Both could make Hermes sound more like, well, me.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://6187708.fs1.hubspotusercontent-na1.net/hubfs/6187708/Depositphotos_336298336_XL.jpeg"
              alt="Illustration of technology design"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full shadow-lg"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Training Data",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal">
            To really nail the voice, I need samples of myself speaking. Not just random phrases, but a range that covers different sounds in English—and some variety in emotion too. That way, the system can capture the way I naturally talk and express myself.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://imageio.forbes.com/specials-images/imageserve/65033b85fb3035d47a2250b8/0x0.jpg?format=jpg&height=600&width=1200&fit=bounds"
              alt="Illustration of training data"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full shadow-lg"
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="overflow-hidden" style={{ animation: "fadeIn 2s" }}>
        <Timeline data={data} />
      </div>
    </div>
  );
}
