import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";  // Import useLocation hook
import { SiTailwindcss } from "react-icons/si";
import { FaReact, FaGooglePlay, FaLinkedin } from "react-icons/fa";
import { IoLogoFirebase } from "react-icons/io5";

function Footer() {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();  // Get current location

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Don't render the Footer on the /Chat route
  if (location.pathname === "/Chat") {
    return null;
  }

  return (
    <div>
      {width > 768 && (
        <div className="footer place-content-center place-items-center pb-4 bg-white">
          <hr className="my-6 h-px border-t-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />

          <div className="py-2 float-left p-12">
            <div className="flex-row">
              <div className="flex justify-center space-x-4">
                <button
                  className="dwml-repo-button items-center justify-center w-auto p-3 rounded-full"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.edwinkassier.ashes",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <FaGooglePlay size={"1.1em"} />
                </button>
              </div>
            </div>
          </div>

          <div className="py-2 float-right p-12">
            <div className="flex justify-center space-x-4">
              <button
                className="dwml-repo-button items-center justify-center w-auto p-3 rounded-full"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/edwin-kassier/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <FaLinkedin size={"1.1em"} />
              </button>
            </div>
          </div>

          <div>
            <div className="footer-copywright">
              <h3>Edwin Kassier © {new Date().getFullYear()}</h3>
            </div>
          </div>

          <div className="flex place-content-center gap-2 pt-1 pb-1">
            <div>
              <SiTailwindcss />
            </div>
            <div>
              <FaReact />
            </div>
            <div>
              <IoLogoFirebase />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
