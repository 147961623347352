import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { GiWingfoot } from "react-icons/gi";
import { useLoadingStore } from "../../lib/stores/stores";
import { ChatBubble, ChatBubbleAvatar, ChatBubbleMessage } from "@/components/ui/chat/chat-bubble";
import { ChatMessageList } from "@/components/ui/chat/chat-message-list";
import { Skeleton } from "@/components/ui/skeleton";
import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";

const ChatComponent = () => {
  const [messages, setMessages] = useState([
    { id: 1, message: "Welcome. How can I assist you today?", sender: "bot" },
  ]);
  const [currentMessage, setCurrentMessage] = useState("");

  const messagesEndRef = useRef(null);

  useEffect(() => {
    useLoadingStore.setState({ loading: false });
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const messageHandler = (body, signals) => {
    try {
      useLoadingStore.setState({ loading: true });

      let config = {
        params: {
          request_text: body.messages[0].message,
        },
      };

      axios
        .get(
          "https://master-hermes-backend-lqfbwlkw2a-uc.a.run.app/api/v1/project/core/process_request",
          config
        )
        .then((res) => {
          signals.onResponse({ text: res.data.message });
          useLoadingStore.setState({ loading: false });
        })
        .catch((error) => {
          signals.onResponse({ error: error.message });
          useLoadingStore.setState({ loading: false });
        });
    } catch (error) {
      signals.onResponse({ error: error.message });
      useLoadingStore.setState({ loading: false });
    }
  };

  const handleInputChange = (e) => {
    setCurrentMessage(e.target.value)
  };

  const handleInputSubmit = (e) => {
    const value = currentMessage
    if (!value.trim()) return;

    const userMessage = {
      id: messages.length + 1,
      message: value,
      sender: "user",
    };
    setMessages((prev) => [...prev, userMessage]);

    const loadingMessage = {
      id: messages.length + 2,
      message: "Typing...",
      sender: "bot",
      isLoading: true,
    };
    setMessages((prev) => [...prev, loadingMessage]);

    const signals = {
      onResponse: (response) => {
        useLoadingStore.setState({ loading: false });
        setMessages((prev) =>
          prev.map((msg) =>
            msg.id === loadingMessage.id
              ? { ...msg, message: response.text || "Error occurred!", isLoading: false }
              : msg
          )
        );
      },
    };

    messageHandler({ messages: [userMessage] }, signals);
  };

  return (
    <div className="flex flex-col w-full h-screen bg-white">
      <div className="flex-1 overflow-hidden md:p-16 pt-16 md:pt-32 space-y-8 pb-30">
        <ChatMessageList className="overflow-auto">
          {messages.map((message) => {
            const variant = message.sender === "user" ? "sent" : "received";
            return (
              <ChatBubble
                key={message.id}
                variant={variant}
                className="transition-all duration-300"
              >
                {variant === "received" && (
                  <ChatBubbleAvatar
                    fallback="H"
                    className="p-1 rounded-full border border-gray-400"
                  >
                    {message.isLoading ? (
                      <Skeleton className="h-10 w-10 rounded-full" />
                    ) : (
                      <GiWingfoot className="h-4 w-4 text-black" />
                    )}
                  </ChatBubbleAvatar>
                )}
                <ChatBubbleMessage
                  className={`${
                    variant === "received"
                      ? "bg-gray-200 text-black"
                      : "bg-gray-600 text-white"
                  } text-sm py-2 px-3 rounded-lg`}
                >
                  {message.isLoading ? (
                    <Skeleton className="h-4 w-32" />
                  ) : (
                    message.message
                  )}
                </ChatBubbleMessage>
              </ChatBubble>
            );
          })}
        </ChatMessageList>
        <div className="pb-4" ref={messagesEndRef} />
      </div>
      <div className="fixed bottom-12 left-0 right-0 px-4 py-3 bg-white">
        <PlaceholdersAndVanishInput
          placeholders={[
            "Ask me anything about Edwin"
          ]}
          onChange={handleInputChange}
          onSubmit={handleInputSubmit}
        />
      </div>
    </div>
  );
};

export default ChatComponent;
