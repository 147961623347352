import React, { useState, useEffect } from "react";
import Preloader from "./screens/Pre";
import Navbar from "./screens/Navbar";
import Home from "./screens/Home/Home";
import Projects from "./screens/Projects/Projects";
import DWML from "./screens/Delos/DWML";
import DS from "./screens/DS/DS";
import DA from "./screens/DA/DA";
import Midas from "./screens/Midas/Midas";
import Orwell from "./screens/Orwell/Orwell"
import CovidPredictor from  "./screens/C19/covid_predictor"
import SensorCharting from "./screens/SensorDP/SensorCharting"
import PrivacyPolicy from "./screens/Privacy/privacypolicy";
import Chat from "./screens/Chat/Chat";
import Footer from "./screens/Footer";
import { Prometheus } from "./screens/Prometheus/prometheus"; 
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from "./screens/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";


function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);
    

    return () => clearTimeout(timer);
  }, []);



  return (
    <Router>
      <ToastContainer />
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Delos" element={<DWML />} />
          <Route path="/DS" element={<DS />} />
          <Route path="/DA" element={<DA />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/Midas" element={<Midas />} />
          <Route path="/Orwell" element={<Orwell />} />
          <Route path="/Prometheus" element={<Prometheus />} />
          <Route path="/CovidPredictor" element={<CovidPredictor />} />
          <Route path="/SensorData" element={<SensorCharting />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
